import { atom } from 'jotai';

const hoveredWorkAtomAtom = atom<number | null>(null);

export const hoverWorkAtom = atom(null, (get, set, value: number | null) => {
  const current = get(hoveredWorkAtomAtom);

  if (current === value) {
    return;
  }

  set(hoveredWorkAtomAtom, value);
});

export const hoveredWorkAtom = atom((get) => get(hoveredWorkAtomAtom));

export const unselectHoveredWorkAtom = atom(null, (get, set) => {
  const current = get(hoveredWorkAtomAtom);

  if (!current) {
    return;
  }

  set(hoveredWorkAtomAtom, null);
});

export const hoveredAtomCreator = (id: number) => {
  const isHoveredAtom = atom((get) => id === get(hoveredWorkAtomAtom));

  return isHoveredAtom;
};
