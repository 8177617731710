import { setScrollIndicatorVisibilityAtom } from '@components/shared/ScrollIndicator';
import { atom } from 'jotai';
import { WorkDto } from '../api/work.interface';
import { unselectHoveredWorkAtom } from './hovering';

const selectedWorkAtomAtom = atom<WorkDto | null>(null);

export const selectWorkAtom = atom(null, (get, set, work: WorkDto | null) => {
  const current = get(selectedWorkAtom);

  if (current?.id === work?.id) {
    return;
  }
  set(unselectHoveredWorkAtom);
  set(selectedWorkAtomAtom, work);
  set(setScrollIndicatorVisibilityAtom, !work);
});

export const selectedWorkAtom = atom((get) => get(selectedWorkAtomAtom));

export const unselectWorkAtom = atom(null, (get, set) => {
  const current = get(selectedWorkAtom);

  if (!current) {
    return;
  }

  set(selectedWorkAtomAtom, null);
  set(setScrollIndicatorVisibilityAtom, true);
});
