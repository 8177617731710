import { Skeleton, Stack, WrapItem } from '@chakra-ui/react';
import { LayoutGroup } from 'framer-motion';
import { WrapContainer } from './wrap-container';

export const SkeletonList = () => (
  <WrapContainer>
    <LayoutGroup>
      {Array(4)
        .fill(0)
        .map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <WrapItem key={i} w={['100%', null, 640]} h={400}>
            <Stack>
              <Skeleton w={['100%', null, 640]} h={250} />
              <Skeleton h={10} w={300} />
              <Skeleton h={5} w={150} />
            </Stack>
          </WrapItem>
        ))}
    </LayoutGroup>
  </WrapContainer>
);
