import { useCallback, useEffect } from 'react';

type UseKeyDownProps = {
  key: string;
  handler: (e: KeyboardEvent) => void;
};

export const useKeyDown = ({ key, handler }: UseKeyDownProps) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (key === event.key) {
        handler(event);
      }
    },
    [handler, key]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown, true);

    return () => {
      window.removeEventListener('keydown', onKeyDown, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
