import { SadSmiley } from '@animations/SadSmiley';
import { Box, Center, Heading, VStack } from '@chakra-ui/react';

export const ErrorLoadingData = () => (
  <Center h='100%' w={800} p={50}>
    <VStack spacing={100}>
      <Box>
        <Box w={400} h={400}>
          <SadSmiley />
        </Box>
        <Heading textAlign='center'>Something failed to load</Heading>
      </Box>
    </VStack>
  </Center>
);
