import {
  Alert,
  AlertIcon,
  Center,
  SkeletonText,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useWorkDetails } from '../api';

type ContentProps = {
  id: number;
};

const WorkCardContent = ({ id }: ContentProps) => {
  const details = useWorkDetails(id);

  if (details.error) {
    return <Error />;
  }

  if (!details.data) {
    return <Loading />;
  }

  return <Text>{details.data.description}</Text>;
};

const Error = () => (
  <Center>
    <Alert status='error'>
      <AlertIcon />
      Failed to fetch data ☹
    </Alert>
  </Center>
);

const Loading = () => (
  <Stack>
    <SkeletonText noOfLines={8} spacing={4} mt={8} skeletonHeight={4} />
  </Stack>
);

export { WorkCardContent };
