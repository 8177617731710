import { Wrap } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';

export const WrapContainer: FC<PropsWithChildren> = ({ children }) => (
  <Wrap
    justify='center'
    spacing='3rem'
    maxW={1800}
    p='10px 0'
    w='100%'
    overflow='visible'
  >
    {children}
  </Wrap>
);
