import { Box, Center, useOutsideClick } from '@chakra-ui/react';
import { MotionBox } from '@components/shared/MotionChakra';
import { useKeyDown } from '@hooks/useKeyDown';
import { PanInfo, useSpring } from 'framer-motion';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { selectedWorkAtom, unselectWorkAtom } from '../atoms/selection';
import { WorkImage, WorkTitle } from '../work-item';
import { CloseButton } from './work-card-close-button';
import { CloseHandler } from './work-card-close-handler';
import { WorkCardContent } from './work-card-content';

const yCloseOffset = 150;

export const WorkCard = () => {
  const cardRef = useRef<HTMLDivElement>(null);
  const unselect = useSetAtom(unselectWorkAtom);
  const work = useAtomValue(selectedWorkAtom);
  const scale = useSpring(1, { damping: 10 });

  const onPanEnd = useCallback(
    (_: Event, info: PanInfo) => {
      scale.set(1);

      const isCloseThresholdMet = info.offset.y >= yCloseOffset;

      if (isCloseThresholdMet) {
        unselect();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scale]
  );

  const onPan = useCallback(
    (_: Event, info: PanInfo) => {
      const isCloseThresholdMet = info.offset.y >= yCloseOffset;
      const newScale = isCloseThresholdMet ? 0.9 : 1;

      if (scale.get() !== newScale) {
        scale.set(newScale);
      }
    },
    [scale]
  );

  useOutsideClick({
    ref: cardRef,
    handler: () => unselect(),
  });

  useKeyDown({
    key: 'Escape',
    handler: () => unselect(),
  });

  if (!work) {
    return null;
  }

  return (
    <>
      <DisableBackgroundScroll />
      <BackgroundOverlay />
      <Center
        top={0}
        left={0}
        bottom={0}
        right={0}
        position='fixed'
        overflowY='auto'
        overflowX='hidden'
      >
        <MotionBox
          position='relative'
          w={['100%', '800px']}
          borderRadius='4px'
          height='100%'
        >
          <MotionBox position='relative' padding={['0', null, '50px 0']}>
            <MotionBox
              position='relative'
              ref={cardRef}
              style={{ scale }}
              layoutId={`work-${work.id.toString()}`}
            >
              <CloseButton />
              <MotionBox position='relative' zIndex={1}>
                <WorkImage
                  id={work.id}
                  title={work.title}
                  imageUrl={work.cover.flexible_url}
                  open
                />
                <WorkTitle
                  id={work.id}
                  title={work.title}
                  caption={work.caption}
                />
                <CloseHandler onPan={onPan} onPanEnd={onPanEnd} />
              </MotionBox>
              <MotionBox
                layoutId={`work-details-${work.id}`}
                p={['4em 2em', null]}
                bg='white'
                color='blackAlpha.800'
                width='100%'
                h='100%'
              >
                <WorkCardContent id={work.id} />
              </MotionBox>
            </MotionBox>
          </MotionBox>
        </MotionBox>
      </Center>
    </>
  );
};

const BackgroundOverlay = () => (
  <Box
    top={0}
    left={0}
    bottom={0}
    right={0}
    bg='rgba(0,0,0,0.3)'
    position='fixed'
    pointerEvents='none'
    overflow='auto'
    backdropBlur='8px'
  />
);

const DisableBackgroundScroll = () => (
  // TODO: how can we use with gatsby head api?
  <Helmet
    htmlAttributes={{
      class: 'no-scroll',
    }}
    bodyAttributes={{
      class: 'no-scroll',
    }}
  />
);
