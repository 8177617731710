import { Box, CloseButton as CCloseButton } from '@chakra-ui/react';
import { useSetAtom } from 'jotai';
import { FC } from 'react';
import { unselectWorkAtom } from '../atoms';

export const CloseButton: FC = () => {
  const unselect = useSetAtom(unselectWorkAtom);

  return (
    <Box
      position='absolute'
      top={25}
      right={25}
      bottom={0}
      zIndex={2}
      mixBlendMode='difference'
    >
      <CCloseButton
        position='sticky'
        top={25}
        w={50}
        h={50}
        borderRadius={25}
        onClick={unselect}
      />
    </Box>
  );
};
