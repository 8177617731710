import { motion } from 'framer-motion';
import { FC, PropsWithChildren } from 'react';

export const FadeIn: FC<PropsWithChildren> = ({ children }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.2 }}
  >
    {children}
  </motion.div>
);
