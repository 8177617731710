import { Heading } from '@chakra-ui/react';
import { MotionBox } from '@components/shared/MotionChakra';
import { FC, memo } from 'react';

type Props = {
  id: number;
  title: string;
  caption: string;
};

const WorkTitle: FC<Props> = memo(({ id, title, caption }) => (
  <MotionBox
    position='absolute'
    layoutId={`work-title-${id}`}
    bottom='0'
    w='100%'
    p='2em'
    bg='rgba(0,0,0,0.5)'
    pointerEvents='none'
  >
    <Heading>{title}</Heading>
    <Heading as='h3' size='md' color='yellow.400'>
      {caption}
    </Heading>
  </MotionBox>
));

export { WorkTitle };
