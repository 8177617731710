import { Paginator } from '@api/dto/PaginationDto.interface';
import { fetcher } from '@api/fetcher';
import axios from 'axios';
import useSWR, { Fetcher } from 'swr';
import { WorkDto } from './work.interface';

const url = `${process.env.GATSBY_TWILL_URL}/api/projects`;

export async function getWorks(signal?: AbortSignal) {
  return axios
    .get<Paginator<WorkDto>>(url, { signal })
    .then((response) => response.data);
}

const getWorkFetcher: Fetcher<Paginator<WorkDto>, string> = (input) =>
  fetcher(input);

export function useWorks() {
  return useSWR(url, getWorkFetcher);
}
