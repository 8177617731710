import { MotionBox } from '@components/shared/MotionChakra';
import { useAtomValue } from 'jotai';
import { FC, memo, useMemo } from 'react';
import { hoveredAtomCreator } from '../atoms';

type Props = {
  id: number;
};

const WorkItemHoverBorder: FC<Props> = memo(({ id }) => {
  const isHovered = useAtomValue(useMemo(() => hoveredAtomCreator(id), [id]));

  if (!isHovered) {
    return null;
  }

  return (
    <MotionBox
      layoutId='project-border'
      borderColor='yellow.500'
      borderWidth={5}
      borderRadius={5}
      position='absolute'
      top={-1}
      left={-1}
      bottom={-1}
      right={-1}
      zIndex={5}
      pointerEvents='none'
    />
  );
});

export { WorkItemHoverBorder };
