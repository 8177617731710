import { Center } from '@chakra-ui/react';
import { SEO } from '@feature/seo';
import { WorkList } from '@feature/work';
import { FC } from 'react';

export const Head = () => <SEO title='Work' />;

const WorkPage: FC = () => {
  return (
    <>
      <SEO title='Work' />

      <Center as='section' marginTop='var(--header-height)'>
        <WorkList />
      </Center>
    </>
  );
};

export default WorkPage;
