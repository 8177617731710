import { Box, Portal } from '@chakra-ui/react';
import { MotionConfig } from 'framer-motion';
import { useRef } from 'react';
import { useWorks } from '../api';
import { WorkCard } from '../work-card';
import { WorkItem } from '../work-item';
import { ErrorLoadingData } from './error-loading-data';
import { FadeIn } from './fade-in';
import { SkeletonList } from './skeleton-list';
import { WrapContainer } from './wrap-container';

export const WorkList = () => {
  const { data, error } = useWorks();
  const portalContainerRef = useRef(null);

  if (error) {
    return <ErrorLoadingData />;
  }

  if (!data) {
    return <SkeletonList />;
  }

  return (
    <MotionConfig
      transition={{
        bounce: 0.5,
        type: 'spring',
        stiffness: 200,
        damping: 30,
      }}
    >
      <FadeIn>
        <WrapContainer>
          {data?.data.map((work) => (
            <WorkItem key={work.id} work={work} />
          ))}
        </WrapContainer>
      </FadeIn>

      <Portal containerRef={portalContainerRef}>
        <WorkCard />
      </Portal>

      <Box ref={portalContainerRef} />
    </MotionConfig>
  );
};
