import { fetcher } from '@api/fetcher';
import axios from 'axios';
import useSWR, { Fetcher } from 'swr';
import { WorkDetailsDto } from './work-details.interface';

export async function getWorkDetails(id: number, signal?: AbortSignal) {
  let url = `${process.env.GATSBY_TWILL_URL}/api/projects/{id}`;
  url = url.replace('{id}', id.toString());

  return axios
    .get<WorkDetailsDto>(url, { signal })
    .then((response) => response.data);
}

const getWorkDetailsFetcher: Fetcher<WorkDetailsDto, string> = (url) =>
  fetcher(url);

export function useWorkDetails(id: number) {
  const url = getUrl(id);
  return useSWR(url, getWorkDetailsFetcher);
}

function getUrl(id: number) {
  let url = `${process.env.GATSBY_TWILL_URL}/api/projects/{id}`;
  url = url.replace('{id}', id.toString());

  return url;
}
