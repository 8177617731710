import { Image } from '@chakra-ui/react';
import { MotionBox } from '@components/shared/MotionChakra';
import { FC } from 'react';

type Props = {
  id: number;
  title: string;
  imageUrl: string;
  open?: boolean;
};

const WorkImage: FC<Props> = ({ id, title, imageUrl, open }) => (
  <MotionBox
    display='block'
    overflow='hidden'
    h={open ? 'initial' : '360px'}
    layoutId={`work-image-${id}`}
  >
    <Image
      w='100%'
      draggable={false}
      objectFit='cover'
      translateY={open ? 0 : -100}
      src={imageUrl}
      alt={title}
    />
  </MotionBox>
);

WorkImage.defaultProps = {
  open: false,
};

export { WorkImage };
