import { motion } from 'framer-motion';

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i: number) => {
    const delay = i * 0.5;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: 'spring', duration: 1.5, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

export const SadSmiley = () => (
  <motion.svg
    viewBox='0 0 2363 2363'
    initial='hidden'
    animate='visible'
    variants={draw}
    fill='none'
  >
    <motion.path
      id='circle'
      d='M1251.31,359.818c464.259,-3.38 745.598,375.697 768.566,837.287c20.31,408.175 -371.923,804.959 -850.147,788.866c-478.381,-16.099 -844.734,-372.006 -816.959,-788.866c29.857,-448.097 429.459,-833.873 898.54,-837.287Z'
      stroke='white'
      strokeWidth={141}
      strokeLinejoin='round'
      strokeLinecap='round'
      variants={draw}
      custom={1}
    />
    <motion.path
      id='circleDroplet'
      d='M1634.79,1917.59c12.098,127.033 27.221,145.18 18.147,205.672c-9.073,60.492 -15.123,-51.418 0,-78.639'
      stroke='white'
      strokeWidth={97}
      strokeLinejoin='round'
      variants={draw}
      custom={1.5}
    />
    <motion.path
      id='leftEyeFirstStroke'
      d='M685.07,798.492c78.639,60.492 308.508,338.754 308.508,338.754'
      stroke='white'
      strokeWidth={141}
      strokeLinejoin='round'
      strokeLinecap='round'
      variants={draw}
      custom={2}
    />
    <motion.path
      id='leftEyeSecondStroke'
      d='M978.455,756.147l-372.025,435.541'
      stroke='white'
      strokeWidth={141}
      strokeLinejoin='round'
      strokeLinecap='round'
      variants={draw}
      custom={2.5}
    />
    <motion.path
      id='rightEyeFirstStroke'
      d='M1362.58,713.803l429.492,447.64'
      stroke='white'
      strokeWidth={141}
      strokeLinejoin='round'
      strokeLinecap='round'
      variants={draw}
      custom={3}
    />
    <motion.path
      id='rightEyeSecondStroke'
      d='M1813.24,610.967l-447.64,523.254'
      stroke='white'
      strokeWidth={141}
      strokeLinejoin='round'
      strokeLinecap='round'
      variants={draw}
      custom={3.5}
    />
    <motion.path
      id='sadness'
      d='M933.086,1606.06c32.211,-144.434 121.028,-201.387 241.967,-208.696c159.744,6.762 247.568,80.95 278.262,208.696'
      stroke='white'
      strokeWidth={141}
      strokeLinejoin='round'
      strokeLinecap='round'
      variants={draw}
      custom={4}
    />
  </motion.svg>
);
