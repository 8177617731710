import { MotionBox } from '@components/shared/MotionChakra';
import { PanInfo } from 'framer-motion';
import { FC } from 'react';

type Props = {
  onPanEnd: (e: Event, info: PanInfo) => void;
  onPan: (e: Event, info: PanInfo) => void;
};

export const CloseHandler: FC<Props> = ({ onPanEnd, onPan }) => {
  return (
    <MotionBox
      position='absolute'
      top={0}
      left={0}
      right={0}
      height='50%'
      onPanEnd={onPanEnd}
      onPan={onPan}
      sx={{
        touchAction: 'none',
      }}
    />
  );
};
