import { Box } from '@chakra-ui/react';
import { MotionBox, MotionListItem } from '@components/shared/MotionChakra';
import { useMotionValue } from 'framer-motion';
import { useAtomValue, useSetAtom } from 'jotai';
import { FC, memo, useCallback } from 'react';
import { WorkDto } from '../api';
import { hoverWorkAtom } from '../atoms';
import { selectedWorkAtom, selectWorkAtom } from '../atoms/selection';
import { WorkImage } from './work-image';
import { WorkItemHoverBorder } from './work-item-hover-border';
import { WorkTitle } from './work-title';

type Props = {
  work: WorkDto;
};

const areEqual = (prev: Readonly<Props>, next: Readonly<Props>) => {
  return prev.work.id === next.work.id;
};

const WorkItem: FC<Props> = memo(({ work }) => {
  const hover = useSetAtom(hoverWorkAtom);
  const select = useSetAtom(selectWorkAtom);
  const selected = useAtomValue(selectedWorkAtom);
  const zIndex = useMotionValue('auto');

  const onLayoutAnimationStart = useCallback(() => {
    zIndex.set('99');
  }, [zIndex]);
  const onLayoutAnimationComplete = useCallback(() => {
    zIndex.set('auto');
  }, [zIndex]);

  return (
    <MotionListItem
      position='relative'
      layoutId={`work-${work.id.toString()}`}
      key={work.id}
      opacity={selected?.id === work.id ? 0 : 1}
      w={['100%', null, 640]}
      bgColor='white'
      onMouseEnter={() => hover(work.id)}
      onLayoutAnimationStart={onLayoutAnimationStart}
      onLayoutAnimationComplete={onLayoutAnimationComplete}
      whileTap={{ scale: 1.1 }}
      style={{
        zIndex,
      }}
    >
      <MotionBox
        borderRadius='5px'
        onClick={() => select(work)}
        cursor='pointer'
      >
        <Box position='relative'>
          <WorkImage
            id={work.id}
            title={work.title}
            imageUrl={work.cover.flexible_url}
          />
        </Box>
      </MotionBox>
      <WorkTitle id={work.id} title={work.title} caption={work.caption} />
      <MotionBox bg='white' w='100%' layoutId={`work-details-${work.id}`} />

      <WorkItemHoverBorder id={work.id} />
    </MotionListItem>
  );
}, areEqual);

export { WorkItem };
